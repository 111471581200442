<template>
  <div class="transactionsTable">
    <Slide>
      <a id="submit-claim" href="#/SubmitClaim">
        <span>Submit Claim</span>
      </a>
      <a v-if="isAdmin == true" id="add-transaction" href="#/AddTransaction">
        <span>Add Transaction</span>
      </a>
      <!-- <a id="edit-table" href="/">
        <span>Edit Table</span>
      </a> -->
      <a id="log-out" href="/">
        <span>Log Out</span>
      </a>
    </Slide>

    <h2 class="my-4">Transactions Table</h2>
    <table class="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Date</th>
          <th scope="col">Paid in</th>
          <th scope="col">Paid out</th>
          <th scope="col">Category</th>
          <th scope="col">Submitted by</th>
          <th scope="col">Description</th>
          <th scope="col">Attachments</th>
          <th scope="col">Status</th>
          <th scope="col">Balance</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(transaction, index) in transactions"
          v-bind:key="transaction.id"
        >
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ convertDate(transaction.timestamp) }}</td>
          <td>
            {{ transaction.value.includes("-") ? "" : "£" + transaction.value }}
          </td>
          <td>
            {{
              transaction.value.includes("-")
                ? transaction.value.replace("-", "£")
                : ""
            }}
          </td>
          <td>{{ transaction.category }}</td>
          <td>{{ transaction.author }}</td>
          <td>{{ transaction.description }}</td>
          <td>
            <div v-html="viewAttatchment(transaction.attachmentUrl)" />
          </td>
          <td>{{ transaction.status }}</td>
          <td>£{{ transaction.balance }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";
import axios from "axios";
import jwt_decode from "jwt-decode";
export default {
  components: {
    Slide,
  },
  data() {
    return {
      transactions: [],
      isAdmin: this.checkIfAdmin(),
    };
  },
  methods: {
    checkIfAdmin: function() {
      const decoded = jwt_decode(localStorage.getItem("jwt"));
      return decoded.roles[0] === 'admin' ? this.isAdmin = true : this.isAdmin = false;
    },
    getData: async function() {
      await axios
        .get("https://api.stokegiffordcc.co.uk/transactions", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          this.transactions = this.sortByTimestamp(response.data);
        })
        .catch((e) => {
          console.log(e);
          this.$router.push("/");
        });
    },
    sortByTimestamp: function(data) {
      return data.sort(function(x, y) {
        return y.timestamp - x.timestamp;
      });
    },
    convertDate: function(timestamp) {
      return new Date(parseInt(timestamp)).toLocaleString().split(",")[0];
    },
    viewAttatchment: function(attachmentUrl) {
      if (attachmentUrl != "N/A") {
        return `<a href="${attachmentUrl}">Click here</a>`;
      }
      return `<p>${attachmentUrl}</p>`;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.bm-burger-button {
    height: 20px;
    top: 33px;
}
#log-out {
  padding-top: 100px;
}
</style>
