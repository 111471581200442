<template>
  <div class="login">
    <h2 class="my-4">Please Login</h2>
    <div class="container login-box col-11 py-4">
      <input
        v-model="username"
        type="text"
        :class="invalidUsername === true ? 'input col-9 error' : 'input col-9'"
        placeholder="Username"
      />
      <input
        v-model="password"
        type="password"
        :class="
          invalidPassword === true
            ? 'mt-4 input col-9 error'
            : 'mt-4 input col-9'
        "
        placeholder="Password"
      />
      <div id="invalid" class="text-danger mt-3 mb-2"></div>
      <button
        class="btn btn-primary button-size mt-2"
        type="login"
        @click="authUser()"
      >
        Login
      </button>
      <div class="my-4">
        <hr class="solid" />
      </div>
      <button
        class="btn btn-secondary button-size"
        type="login"
        @click="$router.push('/CreateUser')"
      >
        Sign up
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      username: "",
      password: "",
      invalidUsername: false,
      invalidPassword: false,
    };
  },
  methods: {
    authUser: async function() {
      this.username == ""
        ? (this.invalidUsername = true)
        : (this.invalidUsername = false);
      this.password == ""
        ? (this.invalidPassword = true)
        : (this.invalidPassword = false);

      if (this.invalidUsername || this.invalidPassword) return;

      this.invalidUsername = false;
      this.invalidPassword = false;

      const params = new URLSearchParams();
      params.append("username", this.username);
      params.append("password", this.password);
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const url = "https://api.stokegiffordcc.co.uk/authenticate";

      await axios
        .post(url, params, config)
        .then((result) => {
          if (result.data && result.status == 200) {
            localStorage.setItem("jwt", result.data.token);
            this.$router.push("/Transactions");
          }
        })
        .catch((thrown) => {
          if (thrown) {
            console.log(thrown.message);
          }
          if (this.username != "" && this.password != "") {
            document.getElementById("invalid").innerHTML =
              "Invalid Username or Password";
          }
        });
    },
  },
};
</script>

<style scoped>
.button-size {
  min-width: 150px;
  color: white;
}
.login-box {
  background-color: #fafafa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 600px;
}
.input {
  height: 35px;
  color: #495057;
  padding-left: 10px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
hr {
  border-top: 1px solid #ced4da;
}
.error {
  border: 1px solid red;
}
</style>
