<template>
  <div class="createUser">
    <h2 class="my-4">Create new account</h2>
    <div class="container create-user-box col-11 py-4">
      <div class="input-row">
        <div class="input-column mx-3">
          <label class="mb-2">First Name*</label>
          <input
            v-model="firstName"
            type="text"
            :class="
              invalidFirstName === true ? 'input-small error' : 'input-small'
            "
            placeholder="First Name"
          />
        </div>
        <div class="input-column mx-3">
          <label class="mb-2">Last Name*</label>
          <input
            v-model="lastName"
            type="text"
            :class="
              invalidLastName === true ? 'input-small error' : 'input-small'
            "
            placeholder="Last Name"
          />
        </div>
      </div>
      <div class="input-column-single mx-3">
        <label class="mt-4 mb-2 d-block">Email address*</label>
        <input
          v-model="emailAddress"
          type="text"
          :class="invalidEmail === true ? 'input error' : 'input'"
          placeholder="Email"
        />
      </div>
      <div class="input-row">
        <div class="input-column mx-3">
          <label class="mt-4 mb-2">Bank Account Number*</label>
          <input
            v-model="bankAccountNumber"
            type="text"
            :class="
              invalidAccountNumber === true
                ? 'input-small error'
                : 'input-small'
            "
            placeholder="12345678"
          />
        </div>
        <div class="input-column mx-3">
          <label class="mt-4 mb-2">Sort Code*</label>
          <input
            v-model="sortCode"
            type="text"
            :class="
              invalidSortCode === true ? 'input-small error' : 'input-small'
            "
            placeholder="00-00-00"
          />
        </div>
      </div>
      <div class="input-column-single mx-3">
        <label class="mt-4 mb-2 d-block">Password*</label>
        <input
          v-model="password"
          type="password"
          :class="invalidPassword === true ? 'input error' : 'input'"
          placeholder="Password"
        />
      </div>
      <div class="input-column-single mx-3">
        <label class="mt-4 mb-2 d-block">Confirm Password*</label>
        <input
          v-model="confPassword"
          type="password"
          :class="invalidPassword === true ? 'input error' : 'input'"
          placeholder="Confirm password"
        />
      </div>
      <label class="mt-4 d-block lead">*These fields are required</label>
      <div id="invalid" class="text-danger mt-2"></div>
      <button
        class="btn btn-primary button-size mt-3"
        type="submit"
        @click="submit()"
      >
        Create user
      </button>
      <div class="my-4">
        <hr class="solid" />
      </div>
      <button
        class="btn btn-secondary button-size"
        type="login"
        @click="$router.push('/')"
      >
        Login
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      emailAddress: "",
      bankAccountNumber: "",
      sortCode: "",
      password: "",
      confPassword: "",
      invalidFirstName: false,
      invalidLastName: false,
      invalidEmail: false,
      invalidAccountNumber: false,
      invalidSortCode: false,
      invalidPassword: false,
    };
  },
  methods: {
    checkPassword: function() {
if (this.password === this.confPassword) {
return this.password}
else {document.getElementById("invalid").innerHTML =
              "Passwords do not match";
              this.invalidPassword = true}
    },
    submit: async function() {
      this.firstName == ""
        ? (this.invalidFirstName = true)
        : (this.invalidFirstName = false);
      this.lastName == ""
        ? (this.invalidLastName = true)
        : (this.invalidLastName = false);
      this.emailAddress == ""
        ? (this.invalidEmail = true)
        : (this.invalidEmail = false);
      this.bankAccountNumber == ""
        ? (this.invalidAccountNumber = true)
        : (this.invalidAccountNumber = false);
      this.sortCode == ""
        ? (this.invalidSortCode = true)
        : (this.invalidSortCode = false);
      this.password == "" || this.confPassword == ""
        ? (this.invalidPassword = true)
        : (this.invalidPassword = false);

      if (
        this.invalidFirstName ||
        this.invalidLastName ||
        this.invalidEmail ||
        this.invalidAccountNumber ||
        this.invalidSortCode ||
        this.invalidPassword
      )
        return;

      this.invalidFirstName = false;
      this.invalidLastName = false;
      this.invalidEmail = false;
      this.invalidAccountNumber = false;
      this.invalidSortCode = false;
      this.invalidPassword = false;

      let createAccountParams = {
        name: this.firstName + " " + this.lastName,
        emailAddress: this.emailAddress,
        bankAccountNumber: this.bankAccountNumber,
        sortCode: this.sortCode.replaceAll("-", ""),
        password: this.checkPassword(),
      };

      await axios
        .post("https://api.stokegiffordcc.co.uk/account", createAccountParams)
        .then((result) => {
          if (result.data && result.status == 201) {
            this.$router.push("/");
          }
        });
    },
  },
};
</script>

<style scoped>
.input {
  width: 432px;
  height: 35px;
  color: #495057;
  padding-left: 10px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-small {
  width: 200px;
  height: 35px;
  color: #495057;
  padding-left: 10px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.create-user-box {
  background-color: #fafafa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 600px;
}
.button-size {
  min-width: 150px;
  color: white;
}
hr {
  border-top: 1px solid #ced4da;
}
.input-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.input-column-single {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.error {
  border: 1px solid red;
}
</style>
