<template>
  <div class="submitClaim">
    <Slide>
      <a id="transactions-table" href="#/Transactions">
        <span>Transactions Table</span>
      </a>
      <a v-if="isAdmin == true" id="add-transaction" href="#/AddTransaction">
        <span>Add Transaction</span>
      </a>
      <!-- <a id="edit-table" href="/">
        <span>Edit Table</span>
      </a> -->
      <a id="log-out" href="/">
        <span>Log Out</span>
      </a>
    </Slide>
    <h2 class="my-4">Please submit your claim here</h2>
    <div class="container submit-claim-box col-11 py-4">
      <label class="mb-2 d-block">Price*</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text height">£</span>
        </div>
        <input type="text" :class="invalidPrice === true ? 'price-input error' : 'price-input'" v-model="price" />
      </div>
      <label class="mt-4 mb-2 d-block">Category</label>
      <input type="text" class="input col-9" placeholder="Claim" disabled />
      <label class="mt-4 mb-2 d-block">Description</label>
      <input
        v-model="description"
        type="text"
        class="input col-9"
        placeholder="Description..."
      />
      <label class="mt-4 mb-2 d-block">Attachments</label>
      <template>
        <b-form-file
          class="col-9"
          multiple
          type="file"
          id="file"
          ref="file"
          v-model="files"
        >
          <template slot="file-name" slot-scope="{ names }">
            <b-badge variant="light">{{ names[0] }}</b-badge>
            <b-badge v-if="names.length > 1" variant="light" class="ml-1">
              + {{ names.length - 1 }} More files
            </b-badge>
          </template>
        </b-form-file>
      </template>
      <label class="mt-4 d-block lead">*These fields are required</label>
      <button
        class="btn btn-primary button-size mt-3"
        type="submit"
        @click="submit()"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Slide } from "vue-burger-menu";
import jwt_decode from "jwt-decode";

const SGCC_S3_BUCKET =
  "https://stoke-gifford-accounting-assets.s3.eu-west-2.amazonaws.com";

export default {
  components: {
    Slide,
  },
  data() {
    return {
      files: [],
      date: "",
      price: "",
      category: "",
      submittedBy: "",
      description: "",
      email: "",
      invalidPrice: false,
      isAdmin: this.checkIfAdmin(),
    };
  },
  methods: {
    checkIfAdmin: function() {
      const decoded = jwt_decode(localStorage.getItem("jwt"));
      return decoded.roles[0] === 'admin' ? this.isAdmin = true : this.isAdmin = false;
    },
    submit: async function() {

      if (this.price == "") return this.invalidPrice = true;

      this.invalidPrice = false;

      const file = this.files[0];

      let addTransactionParams = {
        attachmentUrl: "",
        timestamp: Date.now().toString(),
        value: "-" + this.price,
        description: this.description,
        status: "Pending",
      };

      if (file != undefined) {
        let signedUrl = "";

        const signedUrlResponse = await axios.post(
          "https://api.stokegiffordcc.co.uk/signurl",
          {
            key: this.files[0].name,
            type: this.files[0].type,
          }
        );

        signedUrl = signedUrlResponse.data;

        const response = await axios.put(signedUrl, this.files[0]);
        addTransactionParams.attachmentUrl = `${SGCC_S3_BUCKET}/${response.config.data.name}`;
      }

      await axios
        .post(
          "https://api.stokegiffordcc.co.uk/claim", addTransactionParams,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((result) => {
          if (result.data && result.status == 201) {
            this.$router.push("/Success");
          }
        });
    },
    handleFileUpload: function() {
      this.file = this.$refs.file.files[0];
    },
  },
};
</script>

<style scoped>
.height {
  height: 35px !important;
}
.form-control {
  height: 35px !important;
}
.input {
  height: 35px;
  color: #495057;
  padding-left: 10px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.submit-claim-box {
  background-color: #fafafa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 600px;
}
.button-size {
  min-width: 150px;
  color: white;
}
.price-input {
  height: 35px;
  color: #495057;
  padding-left: 10px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-group {
  display: flex;
  justify-content: center;
}
.error {
  border: 1px solid red;
}
#log-out {
  padding-top: 100px;
}
</style>
