<template>
  <div class="addTransaction">
    <Slide>
      <a id="submit-claim" href="#/SubmitClaim">
        <span>Submit Claim</span>
      </a>
      <a id="transcations-table" href="#/Transactions">
        <span>Transactions Table</span>
      </a>
      <!-- <a id="edit-table" href="/">
        <span>Edit Table</span>
      </a> -->
      <a id="log-out" href="/">
        <span>Log Out</span>
      </a>
    </Slide>
    <h2 class="my-4">Please add transaction here</h2>
    <div class="container add-transaction-box col-11 py-4">
      <label class="mb-2 d-block">Date*</label>
      <input v-model="date" type="date" 
      :class="invalidDate === true ? 'input error' : 'input'"/>
      <div class="input-row">
        <div class="input-column mx-3">
          <label class="mt-4 mb-2">Paid in</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text height">£</span>
            </div>
            <input
              type="text"
              :class="invalidAmount === true ? 'price-input error' : 'price-input'"
              v-model="paidIn"
              :disabled="paidOut.length > 0"
            />
          </div>
        </div>
        <div class="input-column mx-3">
          <label class="mt-4 mb-2">Paid out</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text height">£</span>
            </div>
            <input
              type="text"
              :class="invalidAmount === true ? 'price-input error' : 'price-input'"
              v-model="paidOut"
              :disabled="paidIn.length > 0"
            />
          </div>
        </div>
      </div>
      <label class="mt-4 mb-2 d-block">Category*</label>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text height" for="categories"
            >Categories</label
          >
        </div>
        <select
          v-model="category"
          :class="invalidCategory === true ? 'category-input error' : 'category-input'"
          id="categories"
        >
          <option value="">Select...</option>
          <option value="Subs">Subs</option>
          <option value="Annual Subs">Annual Subs</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <label class="mt-4 mb-2 d-block">Submitted by*</label>
      <input
        v-model="submittedBy"
        type="text"
        :class="invalidSubmittedBy === true ? 'input error' : 'input'"
        placeholder="Name, surname"
        required
      />
      <label class="mt-4 mb-2 d-block">Description</label>
      <input
        v-model="description"
        type="text"
        class="input"
        placeholder="Description..."
      />
      <label class="mt-4 mb-2 d-block">Attachments</label>
      <template>
        <b-form-file
          class="attachment-box"
          multiple
          type="file"
          id="file"
          ref="file"
          v-model="files"
        >
          <template slot="file-name" slot-scope="{ names }">
            <b-badge variant="light">{{ names[0] }}</b-badge>
            <b-badge v-if="names.length > 1" variant="light" class="ml-1">
              + {{ names.length - 1 }} More files
            </b-badge>
          </template>
        </b-form-file>
      </template>
      <label class="mt-4 d-block lead">*These fields are required</label>
      <button
        class="btn btn-primary button-size mt-3"
        type="submit"
        @click="submit()"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";
import axios from "axios";

const SGCC_S3_BUCKET =
  "https://stoke-gifford-accounting-assets.s3.eu-west-2.amazonaws.com";

export default {
  components: {
    Slide,
  },
  data() {
    return {
      files: [],
      date: "",
      paidIn: "",
      paidOut: "",
      category: "",
      submittedBy: "",
      description: "",
      invalidDate: false,
      invalidCategory: false,
      invalidSubmittedBy: false,
      invalidAmount: false,
    };
  },
  methods: {
    submit: async function() {
      this.date == "" ? (this.invalidDate = true) : (this.invalidDate = false);
      this.category == ""
        ? (this.invalidCategory = true)
        : (this.invalidCategory = false);
      this.submittedBy == ""
        ? (this.invalidSubmittedBy = true)
        : (this.invalidSubmittedBy = false);
      this.paidIn == "" && this.paidOut == ""
        ? (this.invalidAmount = true)
        : (this.invalidAmount = false);

      if (
        this.invalidDate ||
        this.invalidCategory ||
        this.invalidSubmittedBy ||
        this.invalidAmount
      )
        return;

      this.invalidDate = false;
      this.invalidCategory = false;
      this.invalidSubmittedBy = false;
      this.invalidAmount = false;

      const file = this.files[0];

      let addTransactionParams = {
        attachmentUrl: "",
        timestamp: Date.now().toString(),
        value: this.paidIn != "" ? this.paidIn : "-" + this.paidOut,
        author: this.submittedBy,
        category: this.category,
        description: this.description,
        status: "Pending",
      };

      if (file != undefined) {
        let signedUrl = "";

        const signedUrlResponse = await axios.post(
          "https://api.stokegiffordcc.co.uk/signurl",
          {
            key: this.files[0].name,
            type: this.files[0].type,
          }
        );

        signedUrl = signedUrlResponse.data;

        const response = await axios.put(signedUrl, this.files[0]);
        addTransactionParams.attachmentUrl = `${SGCC_S3_BUCKET}/${response.config.data.name}`;
      }

      await axios
        .post(
          "https://api.stokegiffordcc.co.uk/transactions",
          addTransactionParams
        )
        .then((result) => {
          if (result.data && result.status == 201) {
            this.$router.push("/Success");
          }
        });
    },
    handleFileUpload: function() {
      this.file = this.$refs.file.files[0];
    },
  },
};
</script>

<style scoped>
.height {
  height: 35px !important;
}
.input {
  width: 432px;
  height: 35px;
  color: #495057;
  padding-left: 10px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.add-transaction-box {
  background-color: #fafafa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 600px;
}
.button-size {
  min-width: 150px;
  color: white;
}
.attachment-box {
  width: 432px;
}
.price-input {
  width: 166px;
  height: 35px;
  color: #495057;
  padding-left: 10px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.price-input:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.category-input {
  width: 330px;
  height: 35px;
  color: #495057;
  padding-left: 10px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-group {
  display: flex;
  justify-content: center;
}
.input-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.input-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.error {
  border: 1px solid red;
}
#log-out {
  padding-top: 100px;
}
</style>
