import Vue from "vue";
import VueRouter from "vue-router";
import SubmitClaim from "../components/SubmitClaim.vue";
import Login from "../components/Login.vue";
import TransactionsTable from "../components/TransactionsTable.vue";
import AddTransaction from "../components/AddTransaction.vue";
import Success from "../components/Success.vue";
import CreateUser from "../components/CreateUser.vue";

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    {
      path: "/SubmitClaim",
      name: "SubmitClaim",
      component: SubmitClaim,
    },
    {
      path: "/",
      name: "Login",
      component: Login,
    },
    {
      path: "/Transactions",
      name: "Transactions",
      component: TransactionsTable,
      beforeEnter: (to, from, next) => {
        if (!localStorage.getItem("jwt")) next({ name: "Login" });
        else next();
      }
    },
    {
      path: "/AddTransaction",
      name: "AddTransaction",
      component: AddTransaction,
    },
    {
      path: "/Success",
      name: "Success",
      component: Success,
    },
    {
      path: "/CreateUser",
      name: "CreateUser",
      component: CreateUser,
    },
  ],
});
